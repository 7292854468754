import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "recharge acea-row row-between-wrapper"
};
const _hoisted_2 = {
  class: "mb-8"
};
const _hoisted_3 = {
  class: "recharge-form__item el-dropdown-link",
  style: {
    "width": "100%"
  }
};
const _hoisted_4 = {
  class: "mb-8"
};
const _hoisted_5 = {
  class: "mb-8"
};
const _hoisted_6 = {
  class: "contDatas acea-row row-between-wrapper"
};
const _hoisted_7 = {
  class: "withdrawal-item"
};
const _hoisted_8 = {
  class: "withdrawal-item"
};
const _hoisted_9 = {
  class: "All_buy_show_dia"
};
const _hoisted_10 = {
  class: "pay-password acea-row row-center-wrapper"
};
import NP from 'number-precision';
import { router } from '@/router';
import { computed, ref } from 'vue';
import { currencysList, withdraw } from '@/http';
import { showToast } from 'vant';
export default {
  __name: 'withdrawal',
  emits: ['back'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const emitBacks = () => {
      emit('back', 0);
    };
    const showPwd = ref(false);
    const amount = ref('');
    const payPassword = ref('');
    const fee = computed(() => {
      if (withdrawalData.value.length && amount.value) {
        return NP.times(amount.value, withdrawalData.value[index.value].fee / 100);
      }
      return 0;
    });
    const show = ref(false);
    const address = ref('');
    const back = () => {
      router.go(-1);
    };
    const actions = ref([]);
    const withdrawalData = ref([]);
    const index = ref(0);
    const typeSelect = item => {
      index.value = item.value;
      show.value = false;
    };
    const _currencysList = () => {
      currencysList({
        type: 'withdraw'
      }).then(({
        data
      }) => {
        withdrawalData.value = data;
        data.map((item, index) => {
          actions.value.push({
            name: `${item.name}-${item.agreement.toUpperCase()}`,
            value: index
          });
        });
      });
    };
    _currencysList();
    const success = value => {
      payPassword.value = value;
      _withdraw();
    };
    const _withdraw = () => {
      withdraw({
        currency_id: withdrawalData.value[index.value].id,
        amount: amount.value,
        addr: address.value,
        pay_password: payPassword.value
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          emit('back', 0);
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_arrow_down = _resolveComponent("arrow-down");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_PassInput = _resolveComponent("PassInput");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "back acea-row row-left",
        onClick: emitBacks
      }, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        size: "20"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('withdraw')), 1)]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('please_select_withdrawal_currency')), 1), withdrawalData.value.length ? (_openBlock(), _createBlock(_component_el_dropdown, {
        key: 0,
        trigger: "click",
        style: {
          "width": "100%"
        },
        onCommand: typeSelect
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actions.value, item => {
            return _openBlock(), _createBlock(_component_el_dropdown_item, {
              command: item
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
              _: 2
            }, 1032, ["command"]);
          }), 256))]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(withdrawalData.value[index.value].name) + "-" + _toDisplayString(withdrawalData.value[index.value].agreement) + " ", 1), _createVNode(_component_el_icon, {
          class: "el-icon--right"
        }, {
          default: _withCtx(() => [_createVNode(_component_arrow_down)]),
          _: 1
        })])]),
        _: 1
      })) : _createCommentVNode("", true)]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('withdrawal_amount')), 1), _createVNode(_component_van_field, {
        border: false,
        placeholder: _ctx.$t('please_enter_withdrawal_amount'),
        class: "br-a__8 mb-18 vantInput",
        modelValue: amount.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => amount.value = $event),
        type: "number"
      }, null, 8, ["placeholder", "modelValue"])]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('withdrawal_address')), 1), _createVNode(_component_van_field, {
        border: false,
        placeholder: _ctx.$t('please_enter_withdrawal_address'),
        class: "br-a__8 mb-18 vantInput",
        modelValue: address.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => address.value = $event)
      }, null, 8, ["placeholder", "modelValue"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('handling_fee')), 1), _createElementVNode("span", null, _toDisplayString(fee.value), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('estimated_actual_arrival')), 1), _createElementVNode("span", null, _toDisplayString(_unref(NP).minus(amount.value, fee.value)), 1)])]), _createVNode(_component_van_button, {
        block: "",
        round: "",
        color: "#56F9AA",
        style: {
          "max-width": "439px",
          "margin": "20px auto"
        },
        onClick: _cache[2] || (_cache[2] = $event => showPwd.value = true)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)]),
        _: 1
      })]), _createVNode(_component_el_dialog, {
        modelValue: showPwd.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showPwd.value = $event),
        width: "448",
        "align-center": "",
        center: "",
        title: _ctx.$t('enter_payment_password'),
        style: {
          "border-radius": "16px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_PassInput, {
          onSuccess: success
        })])])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }
};